// AWS SDK for JavaScript - https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/welcome.html
// AWS SDK for JavaScript v2 - https://github.com/aws/aws-sdk-js
// AWS Clients - https://github.com/aws/aws-sdk-js/tree/master/clients

// import AWS object without services
import AWS from 'aws-sdk/global';

// import individual service
import Polly from 'aws-sdk/clients/polly';

// Three.js
import {Scene, Clock, PerspectiveCamera, Vector3, AnimationClip, AnimationUtils, AudioListener, Quaternion, AmbientLight, PMREMGenerator} from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

import HostObject from 'sumerianhost/src/three.js/HostObject';
import TextToSpeechFeature from 'sumerianhost/src/three.js/awspack/TextToSpeechFeature';
import PointOfInterestFeature from 'sumerianhost/src/three.js/PointOfInterestFeature';
import AnimationFeature from 'sumerianhost/src/three.js/animpack/AnimationFeature';
import {AnimationTypes} from 'sumerianhost/src/core/animpack/AnimationFeature';
import {LayerBlendModes} from 'sumerianhost/src/core/animpack/AnimationLayer';
import {Quadratic} from 'sumerianhost/src/core/animpack/Easing';
import GestureFeature from 'sumerianhost/src/core/GestureFeature';
import LipsyncFeature from 'sumerianhost/src/core/LipsyncFeature';
import ICharacter from './ICharacter';
import {banner} from '../../utils';

const REGION = 'us-east-1';
const IDENTITYPOOLID = 'us-east-1:be0bcebf-7d62-45f9-8257-55894003beb7';
const VERSION = '2.1085.0';

const ANIMATION_TYPE = {
  idle: 'idle',
  walk: 'walk',
};

/**
 * Animation system for 3D character
 */
export class Sumerian {
  private sdk: any;
  private camera: PerspectiveCamera;
  private clock: Clock;
  private renderFn: any[] = [];
  private animationFeature: AnimationFeature;
  private characterPosition: Vector3 = new Vector3(0, 0, 0);
  private currentAnimationType: string = ANIMATION_TYPE.idle;
  public scene: Scene;
  public currentCharacter: ICharacter;

  /**
   * @constructor
   * @param {any} sdk
   * @param {Scene} scene
   * @param {PerspectiveCamera} camera
   */
  constructor(sdk: any, scene: Scene, camera: PerspectiveCamera) {
    this.sdk = sdk;
    this.scene = scene;
    this.camera = camera;
    this.clock = new Clock();

    // Initialize AWS and create Polly service objects
    console.debug('Initialize AWS and create Polly service objects');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    AWS.config.region = REGION;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITYPOOLID,
    });

    banner('aws', AWS);

    //Start the render loop
    this.animate();
  }

  /**
   * Set up AWS Polly service
   */
  public async setupPolly() {
    try {
      const polly = new Polly({region: REGION});
      const presigner = new Polly.Presigner();
      const speechInit = TextToSpeechFeature.initializeService(polly, presigner, VERSION);
      await speechInit;
    } catch (error) {
      console.debug(error);
    }
  }

  /**
   * Control character animations
   * @public
   * @param {string} command
   */
  public async control(command: string) {
    const name = this.currentCharacter.name;
    const host = this.currentCharacter.host;
    const text = this.currentCharacter.text;
    const emot = this.currentCharacter.emot;
    const language = 'en-US';

    switch (command) {
      case 'play':
        console.debug('play audio.');
        await this.playPreprocessdAudio(name, language, host);
        break;

      case 'play_text':
        console.debug('play text to audio.');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        host.TextToSpeechFeature['play'](text);
        break;

      case 'pause':
        console.debug('pause audio.');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        host.TextToSpeechFeature[command](text);
        break;

      case 'resume':
        console.debug('resume audio.');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        host.TextToSpeechFeature[command](text);
        break;

      case 'stop':
        console.debug('stop audio.');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        host.TextToSpeechFeature[command](text);
        break;

      case 'emot':
        console.debug(`play emot.`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        host.GestureFeature.playGesture('Emote', emot);
        break;

      default:
        break;
    }
  }

  /**
   * Load character model and animations
   * @public
   * @param {ICharacter} char
   * @returns
   */
  public async loadCharacter(char: ICharacter | undefined) {
    if (!char) {
      console.log("Can't load character.");
      return;
    }

    //Reset character
    if (this.currentCharacter) {
      //Remove old character model from scene
      this.currentCharacter.node.stop();
    }

    this.currentCharacter = char;
    // Define the glTF assets that will represent the host
    const animFiles = ['stand_idle.glb', 'lipsync.glb', 'gesture.glb', 'emote.glb', 'face_idle.glb', 'blink.glb', 'poi.glb', 'walking.glb'];
    const gestureConfigFile = 'gesture.json';
    const poiConfigFile = 'poi.json';

    const animationPath = char.animationPath;
    const characterFile = char.characterFile;

    const node = await this.sdk.Scene.createNode();
    const initial = {
      url: window.location.origin + characterFile,
      // visible: true,
      localScale: {x: 0.9, y: 0.9, z: 0.9},
      // localPosition: { x: 0, y: 0, z: 0 },
      // localRotation: { x: 0, y: 0, z: 0 }
    };
    node.addComponent('mp.gltfLoader', initial);
    node.position.set(char.position.x, char.position.y, char.position.z);
    this.characterPosition.copy(node.position);
    const q = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), char.direction);
    node.quaternion.copy(q);
    node.start();

    // Asset loader
    const gltfLoader = new GLTFLoader();
    const characterGltf = await gltfLoader.loadAsync(characterFile);

    // Make the offset pose additive
    const [bindPoseOffset]: AnimationClip[] = characterGltf.animations;
    if (bindPoseOffset) {
      AnimationUtils.makeClipAdditive(bindPoseOffset);
    }

    // Load animations
    const clips = await Promise.all(
      animFiles.map(async (filename: string) => {
        const filePath = `${animationPath}/${filename}`;
        const animationGltf = await gltfLoader.loadAsync(filePath);
        return animationGltf.animations;
      }),
    );

    char.node = node;

    const setCharacterData = async () => {
      if (!node.obj3D?.children[0]?.children[0]) {
        setTimeout(() => {
          setCharacterData();
        }, 1000);
        return;
      }

      char.character = node.obj3D.children[0].children[0];
      char.clips = clips;
      char.bindPoseOffset = bindPoseOffset;

      // Find the joints defined by name
      char.audioAttach = char.character.getObjectByName(char.audioAttachJoint);
      char.lookTracker = char.character.getObjectByName(char.lookJoint);

      // Read the gesture config file.
      // This file contains options for splitting up each animation in gestures.glb into 3 sub-animations and initializing them as a QueueState animation.
      char.gestureConfig = await fetch(`${char.animationPath}/${gestureConfigFile}`).then((response) => response.json());

      // Read the point of interest config file.
      // This file contains options for creating Blend2dStates from look pose clips and initializing look layers on the PointOfInterestFeature.
      char.poiConfig = await fetch(`${char.animationPath}/${poiConfigFile}`).then((response) => response.json());

      //Sumerian host
      const host = this.createHost(char);

      // Set up each host to look at the other when the other speaks and at the this.camera when speech ends
      // this.initializePoI(host, char)
    };

    await setCharacterData();
  }

  /**
   * Set position of character
   * @public
   * @param {Vector3} position
   */
  public setCharacterPosition(position: Vector3) {
    this.currentCharacter.node.position.x = position.x;
    this.currentCharacter.node.position.y = position.y;
    this.currentCharacter.node.position.z = position.z;
  }

  /**
   * Set direction of character
   * @public
   * @param {number} direction
   */
  public setCharacterDirection(direction: number) {
    const q = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), direction);
    this.currentCharacter.node.quaternion.x = q.x;
    this.currentCharacter.node.quaternion.y = q.y;
    this.currentCharacter.node.quaternion.z = q.z;
    this.currentCharacter.node.quaternion.w = q.w;
  }

  async playPreprocessdAudio(name: string, language: string, host: any) {
    /**
     * Make sure to replace text with a unique string per audioURL and speechJson, you can keep this as the text you used to play but note that it actually won't be played as the preprocessed audio and speechMarks will be played instead.
     * Make sure to replace speechJson with the preprocessed SpeechMarks JSON Array.
     * Make sure to replace audioURL with a Blob URL of the preprocessed Audio file
     */
    // host.TextToSpeechFeature.play(text, {
    //   speechJson: speechJson,
    //   AudioURL: audioURL
    // });

    console.debug('playPreprocessdAudio', name);

    // Specify local paths. Make sure to update them to where you copy them into under your public/root folder
    const speechPath = `/public/assets/preprocessed/${language}/speech.json`;
    const audioPath = `/public/assets/preprocessed/${language}/speech.mp3`;

    // Fetch resources
    const speechJson = await (await fetch(speechPath)).json();
    const speechText = speechJson.Text.S;
    const speechMarks = speechJson.SpeechMarks.Json;
    const audioBlob = await (await fetch(audioPath)).blob();

    // Create Audio Blob URL
    const audioURL = URL.createObjectURL(audioBlob);

    // Play speech with local assets
    host.TextToSpeechFeature.play(speechText, {
      isGlobal: true,
      volume: 3,
      SpeechMarksJSON: speechMarks,
      AudioURL: audioURL,
    });
  }

  // Initialize the host
  createHost(char: ICharacter | undefined): HostObject | undefined {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    const [idleClips, lipsyncClips, gestureClips, emoteClips, faceIdleClips, blinkClips, poiClips, walkClips] = char.clips;

    const idleClip = idleClips[0];
    const walkClip = walkClips[0];
    const faceIdleClip = faceIdleClips[0];

    const host = new HostObject({owner: char.character, clock: this.clock});

    char.host = host;

    // Add the host to the render loop
    this.renderFn.push(() => {
      host.update();
    });

    // Set up text to speech
    const audioListener = new AudioListener();
    this.camera.add(audioListener);
    host.addFeature(TextToSpeechFeature, false, {
      listener: audioListener,
      attachTo: char.audioAttach,
      voice: char.voice,
      engine: char.voiceEngine,
    });

    // Set up animation
    host.addFeature(AnimationFeature);

    this.animationFeature = host.AnimationFeature as AnimationFeature;

    banner('host', host);
    banner('animationFeature', this.animationFeature);

    // Base idle
    this.animationFeature.addLayer('Base');
    this.animationFeature.addAnimation('Base', ANIMATION_TYPE.idle, AnimationTypes.single, {
      clip: idleClip,
    });
    this.animationFeature.playAnimation('Base', ANIMATION_TYPE.idle);

    // Walk
    this.animationFeature.addAnimation('Base', ANIMATION_TYPE.walk, AnimationTypes.single, {
      clip: walkClip,
    });
    // this.animationFeature.playAnimation('Base', ANIMATION_TYPE.walk)

    // Face idle
    this.animationFeature.addLayer('Face', {
      blendMode: LayerBlendModes.Additive,
    });
    AnimationUtils.makeClipAdditive(faceIdleClip);
    this.animationFeature.addAnimation('Face', faceIdleClip.name, AnimationTypes.single, {
      clip: AnimationUtils.subclip(faceIdleClip, faceIdleClip.name, 1, faceIdleClip.duration * 30, 30),
    });
    this.animationFeature.playAnimation('Face', faceIdleClip.name);

    // Blink
    this.animationFeature.addLayer('Blink', {
      blendMode: LayerBlendModes.Additive,
      transitionTime: 0.075,
    });
    if (blinkClips) {
      blinkClips.forEach((clip: any) => {
        AnimationUtils.makeClipAdditive(clip);
      });
    }
    this.animationFeature.addAnimation('Blink', 'blink', AnimationTypes.randomAnimation, {
      playInterval: 3,
      subStateOptions: blinkClips.map((clip: any) => {
        return {
          name: clip.name,
          loopCount: 1,
          clip,
        };
      }),
    });
    this.animationFeature.playAnimation('Blink', 'blink');

    // Talking idle
    this.animationFeature.addLayer('Talk', {
      transitionTime: 0.75,
      blendMode: LayerBlendModes.Additive,
    });
    this.animationFeature.setLayerWeight('Talk', 0);
    const talkClip = lipsyncClips.find((clip: any) => clip.name === 'stand_talk');
    if (talkClip) {
      lipsyncClips.splice(lipsyncClips.indexOf(talkClip), 1);
      this.animationFeature.addAnimation('Talk', talkClip.name, AnimationTypes.single, {
        clip: AnimationUtils.makeClipAdditive(talkClip),
      });
      this.animationFeature.playAnimation('Talk', talkClip.name);
    }

    // Gesture animations
    this.animationFeature.addLayer('Gesture', {
      transitionTime: 0.5,
      blendMode: LayerBlendModes.Additive,
    });
    if (gestureClips) {
      gestureClips.forEach((clip: any) => {
        const {name} = clip;
        const config = char.gestureConfig[name];

        AnimationUtils.makeClipAdditive(clip);
        if (config !== undefined) {
          config.queueOptions.forEach((option: any) => {
            // Create a subclip for each range in queueOptions
            option.clip = AnimationUtils.subclip(clip, `${name}_${option.name}`, option.from, option.to, 30);
          });
          this.animationFeature.addAnimation('Gesture', name, AnimationTypes.queue, config);
        } else {
          this.animationFeature.addAnimation('Gesture', name, AnimationTypes.single, {clip});
        }
      });
    }

    // Emote animations
    this.animationFeature.addLayer('Emote', {transitionTime: 0.5});
    if (emoteClips) {
      emoteClips.forEach((clip: any) => {
        const {name} = clip;
        this.animationFeature.addAnimation('Emote', name, AnimationTypes.single, {
          clip,
          loopCount: 1,
        });
      });
    }

    // Viseme poses
    this.animationFeature.addLayer('Viseme', {
      transitionTime: 0.12,
      blendMode: LayerBlendModes.Additive,
    });
    this.animationFeature.setLayerWeight('Viseme', 0);

    // Slice off the reference frame
    const blendStateOptions = lipsyncClips.map((clip: any) => {
      AnimationUtils.makeClipAdditive(clip);
      return {
        name: clip.name,
        clip: AnimationUtils.subclip(clip, clip.name, 1, 2, 30),
        weight: 0,
      };
    });
    this.animationFeature.addAnimation('Viseme', 'visemes', AnimationTypes.freeBlend, {
      blendStateOptions,
    });
    this.animationFeature.playAnimation('Viseme', 'visemes');

    // POI poses
    if (char.poiConfig) {
      const entries = (obj) => {
        var ownProps = Object.keys(obj),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
      };

      entries(char.poiConfig).forEach(([key, config]: [string, any]) => {
        this.animationFeature.addLayer(config.name, {
          blendMode: LayerBlendModes.Additive,
        });

        if (config.blendStateOptions) {
          // Find each pose clip and make it additive
          config.blendStateOptions.forEach((clipConfig: any) => {
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            const clip = poiClips.find((c: any) => c.name === clipConfig.clip)!;

            AnimationUtils.makeClipAdditive(clip);
            clipConfig.clip = AnimationUtils.subclip(clip, clip.name, 1, 2, 30);
          });
        } else {
          console.error('config.blendStateOptions is undefined!');
        }

        this.animationFeature.addAnimation(config.name, config.animation, AnimationTypes.blend2d, {
          ...config,
        });
        this.animationFeature.playAnimation(config.name, config.animation);

        // Find and store reference objects
        config.reference = char.character?.getObjectByName(config.reference.replace(':', ''));
      });
    }

    // Apply bindPoseOffset clip if it exists
    if (char.bindPoseOffset !== undefined) {
      this.animationFeature.addLayer('BindPoseOffset', {
        blendMode: LayerBlendModes.Additive,
      });
      this.animationFeature.addAnimation('BindPoseOffset', char.bindPoseOffset.name, AnimationTypes.single, {
        clip: AnimationUtils.subclip(char.bindPoseOffset, char.bindPoseOffset.name, 1, 2, 30),
      });
      this.animationFeature.playAnimation('BindPoseOffset', char.bindPoseOffset.name);
    }

    // Set up Lipsync
    const visemeOptions = {
      layers: [{name: 'Viseme', animation: 'visemes'}],
    };
    const talkingOptions = {
      layers: [
        {
          name: 'Talk',
          animation: 'stand_talk',
          blendTime: 0.75,
          easingFn: Quadratic.InOut,
        },
      ],
    };
    host.addFeature(LipsyncFeature, false, visemeOptions, talkingOptions);

    // Set up Gestures
    host.addFeature(GestureFeature, false, {
      layers: {
        Gesture: {minimumInterval: 3},
        Emote: {blendTime: 0.5, easingFn: Quadratic.InOut},
      },
    });

    // // Set up Point of Interest (POI)
    // host.addFeature(
    //   PointOfInterestFeature,
    //   false,
    //   {
    //     target: this.camera,
    //     lookTracker: char.lookTracker,
    //     scene: this.scene,
    //   },
    //   {
    //     layers: char.poiConfig,
    //   },
    //   {
    //     layers: [{ name: 'Blink' }],
    //   }
    // )

    return host;
  }

  initializePoI(host: HostObject | undefined, char: ICharacter | undefined) {
    try {
      if (!host) {
        throw new Error(`Hosts are empty!`);
      }
      if (!char) {
        throw new Error(`Characters are empty!`);
      }

      // Set up each host to look at the other when the other speaks and at the camera when speech ends
      const onHostStartSpeech = () => {
        // @ts-ignore
        host.hostPoiFeature.setTarget(char.lookTracker);
      };

      const onStopSpeech = () => {
        // @ts-ignore
        host.hostPoiFeature.setTarget(this.camera);
      };

      host.listenTo('play', onHostStartSpeech);
      host.listenTo('resume', onHostStartSpeech);
      TextToSpeechFeature.listenTo('pause', onStopSpeech);
      TextToSpeechFeature.listenTo('stop', onStopSpeech);
    } catch (error) {
      console.debug(error);
      throw new Error(`initializePoI - ${error}`);
    }
  }

  updateCharacterAnimationClip() {
    if (!this.animationFeature) {
      return;
    }
    if (!this.currentCharacter) {
      return;
    }

    if (!this.currentCharacter.node) {
      return;
    }

    //Assign animation clip for conditionals
    const dis = this.characterPosition.distanceTo(new Vector3(this.currentCharacter.node.position.x, this.currentCharacter.node.position.y, this.currentCharacter.node.position.z));
    if (dis === 0) {
      if (this.currentAnimationType !== ANIMATION_TYPE.idle) {
        this.animationFeature.playAnimation('Base', ANIMATION_TYPE.idle);
        this.currentAnimationType = ANIMATION_TYPE.idle;
      }
    } else if (dis > 0) {
      if (this.currentAnimationType !== ANIMATION_TYPE.walk) {
        this.animationFeature.playAnimation('Base', ANIMATION_TYPE.walk);
        this.currentAnimationType = ANIMATION_TYPE.walk;
      }
    }

    this.characterPosition.x = this.currentCharacter.node.position.x;
    this.characterPosition.y = this.currentCharacter.node.position.y;
    this.characterPosition.z = this.currentCharacter.node.position.z;
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.update();
  }

  update() {
    this.renderFn.forEach((fn: any) => {
      fn();
    });

    this.updateCharacterAnimationClip();
  }
}
