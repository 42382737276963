export const size = {
  desktop: '2560px', // 16:9
  laptop: '1440px', // laptop
  pad: '1024px', // iPad Pro
  tablet: '768px', // iPad
  mobileL: '480px', // 8plus
  mobileM: '375px', // iphone 6,7,8
  mobileS: '320px', // iphone 5,4
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  pad: `(max-width: ${size.pad})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
};
