import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {MP_MODEL_DATA} from 'metacore';
import {useStore} from 'state';

type Props = {
  mpModelId: string;
};

const WaypointController: React.FC<Props> = ({mpModelId}) => {
  const avatarNavigation = useStore((state) => state.avatarNavigation);

  const waypoints = useMemo(() => {
    let value = [];
    const d = MP_MODEL_DATA.find((model) => model.code === mpModelId);
    if (d) {
      value = d.waypoints;
    }
    return value;
  }, [mpModelId]);

  const [currentWaypoint, setCurrentWaypoint] = useState(0);

  useEffect(() => {
    setCurrentWaypoint(0);
  }, [waypoints]);

  return (
    <Holder
      variants={{
        visible: {
          opacity: 1,
          transition: {
            when: 'beforeChildren',
            staggerChildren: 0.1,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            when: 'afterChildren',
            staggerChildren: 0.01,
          },
        },
      }}
      initial="hidden"
      exit="hidden"
      animate="visible"
      transition={{duration: 0.6}}
    >
      {waypoints.map((waypoint, index) => (
        <Item
          key={index}
          active={index === currentWaypoint ? 'active' : 'inactive'}
          onClick={() => {
            avatarNavigation.moveTo(waypoint);
            setCurrentWaypoint(index);
          }}
          variants={{
            visible: {opacity: 1, y: 0},
            hidden: {opacity: 0, y: 15},
          }}
        >
          {`Point ${index + 1}`}
        </Item>
      ))}
    </Holder>
  );
};

export default WaypointController;

const Holder = styled(motion.div)`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
`;

const Item = styled(motion.div)`
  width: ${(props) => (props.active === 'active' ? '85px' : '70px')};
  height: 40px;
  background-color: ${(props) => (props.active === 'active' ? '#4f46e5' : '#fff')};
  color: ${(props) => (props.active === 'active' ? '#fff' : '#4f46e5')};
  transition: all 0.2s ease-out;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
`;
