import {Scene, Object3D, Mesh, MeshPhongMaterial} from 'three';
import {Geometry} from 'three/examples/jsm/deprecated/Geometry';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
import {getTimeNow} from '../../utils';

/**
 * Export three.js scene to glb file
 * @param {Scene} scene - three.js scene
 */
export default function ExportScene(scene: Scene) {
  let roomObject: Object3D = null;
  scene.traverse((child: any) => {
    if (child.type === 'Object3D' && child.name.startsWith('ModelMesh')) {
      roomObject = child.clone();
    }
  });

  if (roomObject) {
    const convertedObject = convertMPToThreeMesh(roomObject);
    exportToGLTF(convertedObject);
  }
}

function convertMPToThreeMesh(obj: any) {
  let singleGeometry = new Geometry();
  obj.traverse((child: any) => {
    if (child.type === 'Mesh' && child.name.startsWith('RoomMesh')) {
      child.updateMatrix();
      singleGeometry.merge(new Geometry().fromBufferGeometry(child.geometry), child.matrix);
    }
  });

  const resultMesh = new Mesh(singleGeometry.toBufferGeometry() as any, new MeshPhongMaterial());
  return resultMesh;
}

function exportToGLTF(input: any) {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link); // Firefox workaround, see #6594

  function save(blob: any, filename: any) {
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    // URL.revokeObjectURL( url ); breaks Firefox...
  }

  function saveString(text: any, filename: any) {
    save(new Blob([text], {type: 'text/plain'}), filename);
  }

  function saveArrayBuffer(buffer: any, filename: any) {
    save(new Blob([buffer], {type: 'application/octet-stream'}), filename);
  }

  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    truncateDrawRange: true,
    binary: true,
    maxTextureSize: 1024,
  };

  gltfExporter.parse(
    input,
    (result: any) => {
      const fileName = 'Scene_' + getTimeNow();
      if (result instanceof ArrayBuffer) {
        saveArrayBuffer(result, `${fileName}.glb`);
      } else {
        const output = JSON.stringify(result, null, 2);
        saveString(output, `${fileName}.gltf`);
      }
    },
    options,
  );
}
