const theme = {
  colors: {
    primary: '#6366f1',
    secondary: '#ff4200',
    third: '#ff3608',
    fourth: '#292524',
  },
};

export default theme;
