import {Vector3} from 'three';
import ICharacter from '../libs/Sumerian/ICharacter';

export const CHARACTER_DATA: ICharacter[] = [
  {
    id: 'luke',
    code: 'luke',
    name: 'Luke',
    characterFile: `/public/assets/glTF/characters/adult_male/luke/luke.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_male`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Luke.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'jay',
    code: 'jay',
    name: 'Jay',
    characterFile: `/public/assets/glTF/characters/adult_male/jay/jay.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_male`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'preston',
    code: 'preston',
    name: 'Preston',
    characterFile: `/public/assets/glTF/characters/adult_male/preston/preston.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_male`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'wes',
    code: 'wes',
    name: 'Wes',
    characterFile: `/public/assets/glTF/characters/adult_male/wes/wes.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_male`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_male/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'cristine',
    code: 'cristine',
    name: 'Cristine',
    characterFile: `/public/assets/glTF/characters/adult_female/cristine/cristine.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_female`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'fiona',
    code: 'fiona',
    name: 'Fiona',
    characterFile: `/public/assets/glTF/characters/adult_female/fiona/fiona.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_female`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'grace',
    code: 'grace',
    name: 'Grace',
    characterFile: `/public/assets/glTF/characters/adult_female/grace/grace.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_female`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
  {
    id: 'maya',
    code: 'maya',
    name: 'Maya',
    characterFile: `/public/assets/glTF/characters/adult_female/maya/maya.gltf`,
    animationPath: `/public/assets/glTF/animations/adult_female`,
    audioAttachJoint: 'chardef_c_neckB', // Name of the joint to attach audio to
    lookJoint: 'charjx_c_look', // Name of the joint to use for point of interest target tracking
    voice: 'Matthew', // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html:
    voiceEngine: 'neural', // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html
    position: new Vector3(6, -1.5, 3), // 1.25, 0, 0
    direction: Math.PI / 2,
    host: undefined,
    character: undefined,
    lookTracker: undefined,
    clips: undefined,
    bindPoseOffset: undefined,
    audioAttach: undefined,
    gestureConfig: undefined,
    poiConfig: undefined,
    text: `<speak>
        <amazon:domain name="conversational">
          Hello, my name is Jay.
          I used to only be a host inside Amazon Sumerian, but now you can use me in other Javascript runtime environments like three js and Babylon js.
          Right now, <mark name='{"feature":"PointOfInterestFeature","method":"setTargetByName","args":["chargaze"]}'/> my friend and I here are in three js.
        </amazon:domain>
      </speak>`,
    emot: 'applause', // cheer, bored
    node: undefined,
    outfits: [
      {
        name: 'Hoodie Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/hoodie_jeans.gltf`,
      },
      {
        name: 'Polo Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/polo_jeans.gltf`,
      },
      {
        name: 'Tshirt Jeans',
        file: `/public/assets/glTF/animations/adult_female/alt_outfits/tshirt_jeans.gltf`,
      },
    ],
  },
];
