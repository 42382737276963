import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import boardService from './boardService';

const initialState = {
  boards: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Create new board
export const createBoard = createAsyncThunk('boards/create', async (boardData: any, thunkAPI: any) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await boardService.createBoard(boardData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update user board
export const updateBoard = createAsyncThunk('boards/update', async (boardData: any, thunkAPI: any) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await boardService.updateBoard(boardData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get user boards
export const getBoards = createAsyncThunk('boards/getAll', async (_, thunkAPI: any) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await boardService.getBoards(token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Delete user board
export const deleteBoard = createAsyncThunk('boards/delete', async (id: any, thunkAPI: any) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await boardService.deleteBoard(id, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBoard.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.boards.push(action.payload);
      })
      .addCase(createBoard.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBoards.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBoards.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.boards = action.payload;
      })
      .addCase(getBoards.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBoard.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.boards = state.boards.filter((board) => board._id !== action.payload.id);
      })
      .addCase(updateBoard.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBoard.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.boards = state.boards.filter((board) => board._id !== action.payload.id);
      })
      .addCase(deleteBoard.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {reset} = boardSlice.actions;
export default boardSlice.reducer;
