import React from 'react';
import styled from 'styled-components';
import {Button} from 'primereact/button';
import {useStore} from 'state';

const T2SController: React.FC = () => {
  const sumerian = useStore((state) => state.sumerian);

  const handlePlay = () => {
    sumerian.control('play');
  };

  const handlePause = () => {
    sumerian.control('pause');
  };

  const handleStop = () => {
    sumerian.control('stop');
  };

  const handleResume = () => {
    sumerian.control('resume');
  };

  return (
    <Holder>
      <IconButton icon="pi pi-play" onClick={handlePlay} />
      <IconButton icon="pi pi-pause" onClick={handlePause} />
      <IconButton icon="pi pi-step-forward" onClick={handleResume} />
      <IconButton icon="pi pi-stop" onClick={handleStop} />
    </Holder>
  );
};

export default T2SController;

const Holder = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  padding: 0.5em;
  gap: 0.5em;
`;

const IconButton = styled(Button)`
  width: 80px;
  border-radius: 0;
`;
