import React from 'react';
import styled, {ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle} from 'styled-components';
import Div100vh from 'react-div-100vh';
import {isMobile} from 'react-device-detect';
import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';

//Load primereact styles
import './lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import PrimeReact from 'primereact/api';
import {device} from './device';
PrimeReact.ripple = true;
PrimeReact.inputStyle = 'filled';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background-color: white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #9c9c9c;
    border-radius: 5px;
    border: 3px solid white;
  }

  body {
    color: ${theme.colors.primary};
    font-family: 'Poppins', sans-serif;
    font-Size: 16px;
    touch-action: pan-x pan-y;
    background-color: #f0f0f3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;    
    color: #000;
  }

  p {
  line-height: 1.7;
  }

  ul {
    list-style: none;
  }

  li {
    line-height: 2.2;
  }
`;

const MobileContainer = styled(Div100vh)`
  width: 100vw;
  overflow-y: auto;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
`;

const DesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    font-size: 1.2vw;
  }
  @media ${device.pad} {
    font-size: 0.9em;
  }
`;

type Props = {
  children?: React.ReactNode;
};

const ThemeProvider: React.FC<Props> = ({children}) => {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      {isMobile ? <MobileContainer> {children} </MobileContainer> : <DesktopContainer> {children} </DesktopContainer>}
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
