import React from 'react';
import styled from 'styled-components';
import {device} from 'metacore';
import {MiddleContainer} from 'components/Containers';
import LogoIcon from 'assets/icons/logo-white.svg';

export default function Footer() {
  return (
    <Holder>
      <MiddleContainer>
        <FlexContainer>
          <LogoPart>
            <Logo src={LogoIcon} />
            <LogoLabel>Meta Assistant is a tool for managing your meta avatars.</LogoLabel>
          </LogoPart>
          <LinkPart>
            <ContactPart>
              <LinkItem target="_parent" href="/" rel="noopener noreferrer">
                Explore
              </LinkItem>
            </ContactPart>
            <ContactPart>
              <LinkItem target="_parent" href="/" rel="noopener noreferrer">
                Solutions
              </LinkItem>
            </ContactPart>
            <ContactPart>
              <LinkItem target="_parent" href="/" rel="noopener noreferrer">
                Resources
              </LinkItem>
            </ContactPart>
            <ContactPart>
              <LinkItem target="_parent" href="/" rel="noopener noreferrer">
                About us
              </LinkItem>
            </ContactPart>
          </LinkPart>
        </FlexContainer>
      </MiddleContainer>
    </Holder>
  );
}

const Holder = styled.footer`
  padding: 4em 0 3em 0;
  background-color: #252525;
  min-height: 25em;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LogoPart = styled.div`
  flex-grow: 1;
`;

const Logo = styled.img`
  width: 18em;
`;
const LogoLabel = styled.div`
  padding-top: 0.5em;
  font-size: 0.9em;
  max-width: 40em;
  color: #adadad;
`;

const LinkPart = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContactPart = styled.div`
  padding-top: 2.5em;
  font-size: 0.9em;
  min-width: 14em;
  max-width: 20em;
  @media ${device.mobileL} {
    padding-top: 0.5em;
  }
`;
const LinkItem = styled.a`
  font-size: 1.5em;
  line-height: 1;
  font-weight: 700;
  color: #7b8694;
  transition: all 300ms ease;
  &:hover {
    text-decoration: underline;
    color: #f9f9f9;
  }
`;
