import React from 'react';
import {ThemeProvier} from 'metacore';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {store} from 'store';

import Header from 'components/Header';
import Footer from 'components/Footer';
import {DemoRoute, LandingRoute, PrimaryRoutes} from 'dataset/routes';

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvier>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path={LandingRoute.path} element={LandingRoute.component} />
            <Route path={DemoRoute.path} element={DemoRoute.component} />
            {PrimaryRoutes.map(({path, component}, index) => (
              <Route key={index} path={path} element={component} />
            ))}
          </Routes>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </ThemeProvier>
    </Provider>
  );
}
