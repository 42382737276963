import React from 'react';
import styled from 'styled-components';
import {MiddleContainer} from 'components/Containers';
import PageTransitionHolder from 'components/PageTransitionHolder';

export default function Pricing() {
  return (
    <PageTransitionHolder>
      <Holder>Pricing page</Holder>
    </PageTransitionHolder>
  );
}

const Holder = styled(MiddleContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
`;
