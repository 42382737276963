import React, {useState} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {AnimatePresence, motion} from 'framer-motion';
import {Button} from 'primereact/button';

import Loader from 'components/Loader';

import Video from 'assets/videos/demo.mp4';

export default function DemoVideo({delay}) {
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Holder transition={{delay: delay || 0.3, duration: 0.6}} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}}>
      <Loader visible={!isReady} />
      <AnimatePresence>
        {isReady && !isPlaying && (
          <PreviewContainer transition={{delay: 0.3, duration: 0.3}} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <PreviewContent>
              <PlayButton icon="pi pi-play" className="p-button-rounded p-button-primary" onClick={() => setIsPlaying(true)} />
            </PreviewContent>
          </PreviewContainer>
        )}
      </AnimatePresence>
      <Player
        url={Video}
        width="100%"
        height="100%"
        playsinline={true}
        controls={true}
        playing={isPlaying}
        stopOnUnmount={true}
        loop={false}
        muted={false}
        onReady={() => {
          setIsReady(true);
        }}
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
    </Holder>
  );
}

const Holder = styled(motion.div)`
  position: relative;
  width: 100%;
`;

const Player = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
`;

const PreviewContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
`;

const PreviewContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000056;
`;

const PlayButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  padding: 2em !important;
`;
