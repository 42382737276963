import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useNavigate, useLocation} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {motion, AnimatePresence} from 'framer-motion';
import {Button} from 'primereact/button';

import {PrimaryRoutes} from 'dataset/routes';
import {device} from 'metacore';
import {useStore} from 'state';
import NavMenuButton from 'components/NavMenuButton';
import {MiddleContainer} from 'components/Containers';

import LogoIcon from 'assets/icons/logo.svg';

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isPadScreen = useMediaQuery({query: device.pad});
  const menuVisible = useStore((state) => state.menuVisible);
  const setMenuVisible = useStore((state) => state.setMenuVisible);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Holder>
      <MiddleContainer>
        <FlexHolder>
          <a href="/" target="_parent" rel="noreferrer">
            <Logo alt="logo" src={LogoIcon} />
          </a>
          {isPadScreen ? (
            <>
              <NavMenuButton />
              <AnimatePresence>
                {menuVisible && (
                  <NavMobile
                    initial="hidden"
                    animate={menuVisible ? 'visible' : 'hidden'}
                    exit="hidden"
                    variants={{
                      visible: {
                        x: 0,
                        transition: {
                          ease: [0.0, 0.0, 0.21, 0.89],
                          when: 'beforeChildren',
                          staggerChildren: 0.15,
                        },
                      },
                      hidden: {
                        x: '-100%',
                        transition: {
                          ease: [0.0, 0.0, 0.21, 0.89],
                          when: 'afterChildren',
                          staggerChildren: 0.07,
                        },
                      },
                    }}
                  >
                    <SmallLogo alt="logo" src={LogoIcon} />
                    <CloseButton
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text p-button-icon-only"
                      onClick={() => {
                        setMenuVisible(false);
                      }}
                    />
                    {PrimaryRoutes.map(({path, title}, index) => (
                      <NavItemMobile
                        key={index}
                        selected={path === location.pathname}
                        onClick={() => {
                          navigate(path);
                          setMenuVisible(false);
                        }}
                        variants={{
                          visible: {opacity: 1, y: 0},
                          hidden: {opacity: 0, y: 0},
                        }}
                      >
                        {title}
                      </NavItemMobile>
                    ))}
                  </NavMobile>
                )}
              </AnimatePresence>
            </>
          ) : (
            <Nav>
              {PrimaryRoutes.map(({path, title}, index) => (
                <NavItem
                  key={index}
                  active={location.pathname === path ? 1 : 0}
                  onClick={() => {
                    navigate(path);
                  }}
                >
                  <div>{title}</div>
                </NavItem>
              ))}
            </Nav>
          )}
        </FlexHolder>
      </MiddleContainer>
    </Holder>
  );
}

const Holder = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background: white;
  transition: all 0.2s ease-out;
`;

const FlexHolder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.5em 0;
  @media ${device.pad} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1em;
  }
`;

const Logo = styled.img`
  height: 3em;
  @media ${device.mobileL} {
    height: 1.7em;
  }
`;

const SmallLogo = styled.img`
  position: absolute;
  top: 2em;
  left: 1em;
  z-index: 1;
  width: 12em;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 1.3em;
  right: 1em;
  z-index: 1;
`;

const Nav = styled.ul`
  display: flex;
  align-items: center;
`;
const NavItem = styled.li`
  color: ${(props) => (props.active ? '#1c2731' : '#95a7b9')};
  font-weight: 700;
  font-size: 1.2em;
  line-height: 1;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  margin: 0 1.5em;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.5em;
    width: 0;
    height: 2px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: width 0.5s ease;
  }
  &:hover::after {
    width: 4em;
  }
  @media ${device.laptop} {
    font-size: 1em;
  }
  > img {
    width: 0.8em;
  }
`;

const NavMobile = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2em 1em 2em 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;
const NavItemMobile = styled(motion.div)`
  color: ${({selected}) => (selected ? '#144A68' : '#7B8694')};
  font-weight: 700;
  font-size: 1.5em;
  cursor: pointer;
  width: 100%;
  padding: 0.8em 1em;
  text-align: center;
  border-left: ${({selected}) => (selected ? '5px solid #f43f53' : '5px solid #ffffff00')};
  transition: all 300ms ease-out;
`;
