import React from 'react';
import styled from 'styled-components';
import {Button} from 'primereact/button';

type Props = {
  action: Function;
};

const FlyController: React.FC<Props> = ({action}) => {
  const handlePlay = () => {
    action();
  };

  const handleStop = () => {};

  return (
    <Holder>
      <IconButton icon="pi pi-play" onClick={handlePlay} />
      <IconButton icon="pi pi-stop" onClick={handleStop} />
    </Holder>
  );
};

export default FlyController;

const Holder = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.5em;
  display: flex;
  padding: 0.5em;
  gap: 0.5em;
`;

const IconButton = styled(Button)`
  width: 80px;
  border-radius: 0;
`;
