import {Vector3} from 'three';

export const MP_SDK_KEY = 'b2c46f3d92a34240a538e40d3ebbe1ff';
export const MP_MODEL_DATA = [
  {
    code: '22Ub5eknCVx',
    name: 'Southern California Luxury Home',
    summary: 'This majestic looking home has wood flooring, crown molding, is a great family home. This tour was shot with the Matterport Pro2 3D camera.',
    waypoints: [new Vector3(6, -1.66, 3), new Vector3(3.37, -1.66, 2.69), new Vector3(6.7, -1.67, 9.09), new Vector3(8.4, 1.735, 9.1), new Vector3(-0.25, 1.735, 0.54)],
    camera: {
      rotation: {x: -6.9, y: 106},
    },
  },
  {
    code: 'nYhmHzFbGXD',
    name: 'Cavco Model - Mountain Ridge',
    summary: 'Cavco Durango proudly introduces the Mountain Ridge Series with 35 brand new floor plans.',
    waypoints: [new Vector3(-3.5, 0, 6.9), new Vector3(-2.4, 0, 5.6), new Vector3(-6.3, 0, 3.2)],
    camera: {
      rotation: {x: 0, y: 0},
    },
  },
  {
    code: 'eYKQzpqEXjg',
    name: 'Casa Montaña',
    summary: '',
    waypoints: [new Vector3(4, 0, -5.6), new Vector3(3.6, 0, 0.9), new Vector3(9.3, 0, 10)],
    camera: {
      rotation: {x: 0, y: -90},
    },
  },
];
