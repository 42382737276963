import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import styled from 'styled-components';

export default function PageTransitionHolder(props) {
  const {children} = props;

  return (
    <AnimatePresence>
      <RouteContainer {...AnimationSettings}>{children}</RouteContainer>
    </AnimatePresence>
  );
}

const RouteContainer = styled(motion.div)`
  width: 100%;
  height: fit-content;
`;

const AnimationSettings = {
  transition: {duration: 0.5},
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
};
