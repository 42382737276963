import React from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import PageTransitionHolder from 'components/PageTransitionHolder';
import {MiddleContainer} from 'components/Containers';
import WaveText from 'components/WaveText';
import TextButton from 'components/Buttons/TextButton';
import DemoVideo from './components/DemoVideo';
import {device} from 'metacore';
import {DemoRoute} from 'dataset/routes';

export default function Landing() {
  const navigate = useNavigate();
  return (
    <PageTransitionHolder>
      <Holder>
        <Title>
          {/* <WaveText text="" delay={0.5} duration={0.02} /> */}
          3D Virtual Real Estate Showing Assistant for Super Efficiency
        </Title>
        <SubTitle>
          {/* <WaveText text="" delay={1.5} duration={0.01}/> */}
          Meta 3D, a Virtual Real Estate Showing Assistant, provides Commercial and residential Brokers, Realtors, Home Builder and the Hospitality industry the edge and efficiency that is needed the tough times ahead.
          <br />
          Meta Assistant speaks 74 languages 76 dialects and has 352 male, female, and child voices with lip-synching, based on only a single English text.
          <br />
          Soon it will understand many spoken languages and my responses, intelligence and accuracy will surprise you.
          <br />
          As it grows and matures, it will be indistinguishable from a real human being, and I will be able to help in many industries.
          <br />
          It can be animated, and I can display many emotions as well.
          <br />
          The hairstyle, body style, makeup, and skin tone can be customized, and it has a large wardrobe for all occasions.
          <br />
          Your company logo can appear anywhere on its attire.
          <br />
          I can appear in any Virtual Reality and Augmented Reality environment on all platforms and operating systems.
          <br />
          As a Meta Assistant, real estate agent, or showing assistant, primarily it appears in a Matterport VR scene on the Thee.JS platform.
        </SubTitle>
        <Controls>
          <TextButton
            delay={2.1}
            onClick={() => {
              navigate(DemoRoute.path);
            }}
          >
            Try the live demo
          </TextButton>
        </Controls>
        <div className="w-full mt-5">
          <DemoVideo delay={1.5} />
        </div>
        <div className="mt-5 mb-8 flex flex-column align-items-center">
          <H1>
            <WaveText text="How does it work?" delay={1.6} duration={0.02} />
          </H1>
          <H2>
            <WaveText text="This app was built by a team of developers who are passionate about building great products." delay={1.6} duration={0.01} />
          </H2>
        </div>
      </Holder>
    </PageTransitionHolder>
  );
}

const Holder = styled(MiddleContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
`;

const Title = styled.h1`
  max-width: 20em;
  color: #1c2280;
  font-size: 3em;
  font-weight: 900;
  margin: 2em 0 1em 0;
  padding: 0;
  @media ${device.mobileL} {
    font-size: 2em;
  }
`;

const SubTitle = styled.h2`
  max-width: 70em;
  color: #252525;
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 1em 0;
  padding: 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  padding: 1em;
`;

const H1 = styled.h1`
  max-width: 18em;
  color: #1c2280;
  font-size: 2em;
  font-weight: 700;
  margin: 2em 0 1em 0;
  padding: 0;
`;

const H2 = styled.h2`
  max-width: 35em;
  color: #919fb9;
  font-size: 1em;
  font-weight: 600;
  margin: 0 0 1em 0;
  padding: 0;
`;
