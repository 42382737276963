/**
 * Global State of the app
 */

import {AvatarNavigation, CHARACTER_DATA, MP_MODEL_DATA, Sumerian} from 'metacore';
import {Vector3} from 'three';
import create from 'zustand';

type State = {
  sumerian: Sumerian;
  setSumerian: (sumerian: Sumerian) => void;
  avatarNavigation: AvatarNavigation;
  setAvatarNavigation: (avatarNavigation: AvatarNavigation) => void;
  /////////////////////////////////////////////////////////////////
  //Header
  /////////////////////////////////////////////////////////////////
  headerVisible: boolean;
  setHeaderVisible: (headerVisible: boolean) => void;
  menuVisible: boolean;
  setMenuVisible: (menuVisible: boolean) => void;
  /////////////////////////////////////////////////////////////////
  //Board form
  /////////////////////////////////////////////////////////////////
  selectedMPModelId: string;
  setSelectedMPModelId: (selectedMPModelId: string) => void;
  characterName: string;
  setCharacterName: (characterName: string) => void;
  selectedCharacterId: string;
  setSelectedCharacterId: (selectedCharacterId: string) => void;
  characterPosition: Vector3;
  setCharacterPosition: (characterPosition: Vector3) => void;
  characterDirection: number;
  setCharacterDirection: (characterDirection: number) => void;
};

export const useStore = create<State>((set) => ({
  sumerian: null,
  setSumerian: (sumerian) => set({sumerian}),
  avatarNavigation: null,
  setAvatarNavigation: (avatarNavigation) => set({avatarNavigation}),
  /////////////////////////////////////////////////////////////////
  //Header
  /////////////////////////////////////////////////////////////////
  headerVisible: true,
  setHeaderVisible: (headerVisible) => set({headerVisible}),
  menuVisible: false,
  setMenuVisible: (menuVisible) => set({menuVisible}),
  /////////////////////////////////////////////////////////////////
  //Board form
  /////////////////////////////////////////////////////////////////
  selectedMPModelId: MP_MODEL_DATA[0].code,
  setSelectedMPModelId: (selectedMPModelId) => set({selectedMPModelId}),
  characterName: 'John Doe',
  setCharacterName: (characterName) => set({characterName}),
  selectedCharacterId: CHARACTER_DATA[0].id,
  setSelectedCharacterId: (selectedCharacterId) => set({selectedCharacterId}),
  characterPosition: new Vector3(CHARACTER_DATA[0].position.x, CHARACTER_DATA[0].position.y, CHARACTER_DATA[0].position.z),
  setCharacterPosition: (characterPosition) => set({characterPosition}),
  characterDirection: CHARACTER_DATA[0].direction,
  setCharacterDirection: (characterDirection) => set({characterDirection}),
}));
