import Deferred from '../Deferred';

/**
 * Class that can play back audio generated by AWS Polly and synchronized emit
 * speechmark messages.
 *
 * @abstract
 */
class AbstractSpeech {
  /**
   * @constructor
   *
   * @param {core/TextToSpeechFeature} speaker - The feature that owns the Speech and
   * will emit speechmark messages.
   * @param {string} text - The text of the speech.
   * @param {Array.<Object>} [speechmarks=[]] - An array of speechmark objects representing
   * the text and timing of the speech.
   */
  constructor(speaker, text, speechmarks = []) {
    this._speaker = speaker;
    this._text = text;
    this._speechmarks = speechmarks;
    this._speechmarkOffset = 0;
    this._reset();
  }

  /**
   * Reset tracking properties.
   *
   * @private
   *
   * @param {number} [currentTime=0] - Time to use for _startTime.
   */
  _reset(currentTime = 0) {
    this._startTime = currentTime;
    this._localTime = 0;
    this._pauseTime = 0;
    this._playing = false;
    this._markIter = this._speechmarks.values();
    const {value, done} = this._markIter.next();
    this._currentMark = value;
    this._endTime = this._speechmarks.length ? this._speechmarks[this._speechmarks.length - 1].time : 0;
    this._done = done;
    this._promise = null;
  }

  /**
   * Create a new promise that will stop playback and emit messages for this speech.
   *
   * @param {Function=} onFinish - Funciton to execute once the speech stops.
   * @param {onError=} onError - Function to execute if the speech encounters an
   * error.
   * @param {Function=} onInterrupt - Function to execute if the speech is canceled.
   *
   * @returns {Deferred}
   *
   * @private
   */
  _createPromise(onFinish, onError, onInterrupt) {
    const onResolve = (value) => {
      this._playing = false;

      this._speaker.emit(this._speaker.constructor.EVENTS.stop, this);
      this._speaker.constructor.emit(this._speaker.constructor.EVENTS.stop, this);

      if (typeof onFinish === 'function') {
        onFinish(value);
      }
    };

    const onReject = (e) => {
      this._playing = false;

      this._speaker.emit(this._speaker.constructor.EVENTS.stop, this);
      this._speaker.constructor.emit(this._speaker.constructor.EVENTS.stop, this);

      console.error(`${this.constructor.name} encountered an unexpected error: ${e}`);

      if (typeof onError === 'function') {
        onError(e);
      }
    };

    const onCancel = (value) => {
      this._playing = false;

      this._speaker.emit(this._speaker.constructor.EVENTS.interrupt, this);
      this._speaker.constructor.emit(this._speaker.constructor.EVENTS.interrupt, this);

      if (typeof onInterrupt === 'function') {
        onInterrupt(value);
      }
    };

    this._promise = new Deferred(undefined, onResolve, onReject, onCancel);

    return this._promise;
  }

  /**
   * Return whether or not the speech has reached it's end.
   *
   * @private
   *
   * @returns {boolean}
   */
  _checkFinished() {
    return this._done && this._localTime >= this._endTime;
  }

  /**
   * Gets the playback state of the audio.
   *
   * @readonly
   * @type {boolean}
   */
  get playing() {
    return this._playing;
  }

  /**
   * Gets the text of the speech.
   *
   * @readonly
   * @type {string}
   */
  get text() {
    return this._text;
  }

  /**
   * Gets a shallow copy of the speechmarks array for the speech.
   *
   * @readonly
   * @type {Array.<Object>}
   */
  get speechmarks() {
    return [...this._speechmarks];
  }

  /**
   * Gets and sets the number of seconds to offset speechmark emission.
   * @type {number}
   */
  get speechmarkOffset() {
    return this._speechmarkOffset / 1000;
  }

  set speechmarkOffset(offset) {
    this._speechmarkOffset = offset * 1000; // Store as milliseconds
  }

  /**
   * Emit speechmark messages as they are encountered in sync with audio.
   *
   * @param {number} currentTime - Current global time when update was called.
   */
  update(currentTime) {
    if (!this._playing) {
      return;
    }

    // Update local audio time
    this._localTime = currentTime - this._startTime;

    if (!this._done) {
      // Emit speechmark messages for marks up to the current time
      while (!this._done && this._currentMark.time + this._speechmarkOffset <= this._localTime) {
        this._speaker.emit(this._speaker.constructor.EVENTS[this._currentMark.type], {
          speech: this,
          mark: this._currentMark,
        });
        const {value, done} = this._markIter.next();

        this._currentMark = value;
        this._done = done;
      }
    }

    // End playback
    if (this._checkFinished()) {
      this.stop();
      this._reset();
    }
  }

  /**
   * Play the speech from the beginning.
   *
   * @param {number} currentTime - Current global time when play was called.
   * @param {Function=} onFinish - Optional function to execute once the speech
   * promise resolves.
   * @param {Function=} onError - Optional function to execute if the speech
   * encounters and error during playback.
   * @param {Function=} onInterrupt - Optional function to execute if the speech
   * is canceled.
   *
   * @returns {Deferred} Resolves once the speech reaches the end of playback.
   */
  play(currentTime, onFinish, onError, onInterrupt) {
    this._reset(currentTime);
    this._playing = true;

    this._speaker.emit(this._speaker.constructor.EVENTS.play, this);
    this._speaker.constructor.emit(this._speaker.constructor.EVENTS.play, this);

    return this._createPromise(onFinish, onError, onInterrupt);
  }

  /**
   * Pause the speech at the current time.
   *
   * @param {number} currentTime - Current global time when pause was called.
   */
  pause(currentTime) {
    this._playing = false;
    this._pauseTime = currentTime;

    this._speaker.emit(this._speaker.constructor.EVENTS.pause, this);
    this._speaker.constructor.emit(this._speaker.constructor.EVENTS.pause, this);
  }

  /**
   * Resume the speech at the current time.
   *
   * @param {number} currentTime - Current global time when resume was called.
   * @param {Function=} onFinish - Optional function to execute once the speech
   * promise resolves.
   * @param {Function=} onError - Optional function to execute if the speech
   * encounters and error during playback.
   * @param {Function=} onInterrupt - Optional function to execute if the speech
   * is canceled.
   *
   * @returns {Deferred} Resolves once the speech reaches the end of playback.
   */
  resume(currentTime, onFinish, onError, onInterrupt) {
    // Play from the beginning if the speech hasn't played yet
    if (!this._promise) {
      this._reset(currentTime);
      this._createPromise(onFinish, onError, onInterrupt);
    }

    this._playing = true;
    this._startTime += currentTime - this._pauseTime;

    this._speaker.emit(this._speaker.constructor.EVENTS.resume, this);
    this._speaker.constructor.emit(this._speaker.constructor.EVENTS.resume, this);

    return this._promise;
  }

  /**
   * Cancels playback of the speech at the current time. Cancel the speech promise.
   */
  cancel() {
    if (this._promise) {
      this._promise.cancel();
      this._promise = null;
    }

    this._playing = false;
  }

  /**
   * Stop the speech and reset time to the beginning. Resolve the speech promise.
   */
  stop() {
    if (this._promise) {
      this._promise.resolve();
      this._promise = null;
    }

    this._playing = false;
  }
}

export default AbstractSpeech;
