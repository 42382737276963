import React, {useState} from 'react';
import styled from 'styled-components';
import PageTransitionHolder from 'components/PageTransitionHolder';
import MainScene from 'components/MainScene';
import Loader from 'components/Loader';
import {useStore} from 'state';

export default function Demo() {
  const selectedMPModelId = useStore((state) => state.selectedMPModelId);
  const characterPosition = useStore((state) => state.characterPosition);
  const characterDirection = useStore((state) => state.characterDirection);
  const selectedCharacterId = useStore((state) => state.selectedCharacterId);

  const [isLoading, setIsLoading] = useState(true);

  return (
    <PageTransitionHolder>
      <Holder>
        <Loader visible={isLoading} />
        <MainScene
          mpModelId={selectedMPModelId}
          characterId={selectedCharacterId}
          characterPosition={characterPosition}
          characterDirection={characterDirection}
          onLoad={(isLoaded) => {
            setIsLoading(isLoaded);
          }}
        />
      </Holder>
    </PageTransitionHolder>
  );
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
`;
