import React, { Component } from 'react';
import styled from 'styled-components';

interface Props {
  src: string;
}

export class Frame extends Component<Props, {}> {
  render() {
    return (
      <FrameWrapper>
        <iframe id="sdk-iframe" src={this.props.src + '&title=0&qs=1&hr=0&brand=0&help=0'}></iframe>
      </FrameWrapper>
    );
  }
}

const FrameWrapper = styled.div`
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border-width: 0px;
  }
`;
