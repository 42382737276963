/* eslint-disable import/prefer-default-export */

/**
 * Indicates the type of host build.
 *
 * @type {string}
 */
const env = 'core';

export {env};
