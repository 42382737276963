import React from 'react';
import Pricing from 'pages/Pricing';
import Blog from 'pages/Blog';
import Business from 'pages/Business';
import Community from 'pages/Community';
import Landing from 'pages/Landing';
import Demo from 'pages/Demo';

export const LandingRoute = {
  path: '/',
  title: 'Landing',
  component: <Landing />,
};

export const DemoRoute = {
  path: '/demo',
  title: 'Demo',
  component: <Demo />,
};

export const PrimaryRoutes = [
  {
    path: '/pricing',
    title: 'Pricing',
    component: <Pricing />,
  },
  {
    path: '/blog',
    title: 'Blog',
    component: <Blog />,
  },
  {
    path: '/business',
    title: 'Business',
    component: <Business />,
  },
  {
    path: '/community',
    title: 'Community',
    component: <Community />,
  },
];
