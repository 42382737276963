import React from 'react';
import styled from 'styled-components';
import Spinner from 'components/Spinner';
import {AnimatePresence, motion} from 'framer-motion';

export default function Loader({visible = false}) {
  return (
    <AnimatePresence>
      {visible && (
        <Pivot>
          <Holder transition={{duration: 0.3}} initial={{opacity: 0}} exit={{opacity: 0}} animate={{opacity: 1}}>
            <Spinner size={50} />
          </Holder>
        </Pivot>
      )}
    </AnimatePresence>
  );
}

const Pivot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const Holder = styled(motion.div)`
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
