import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Frame, GetSDK, initComponents} from '@mp/common';
import {Vector3, Quaternion, Euler, Matrix4, WebGLRenderer, sRGBEncoding} from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {cameraInputType} from '@mp/common/src/sdk-components/Camera';
import {AvatarNavigation, CHARACTER_DATA, MP_MODEL_DATA, MP_SDK_KEY, Sumerian} from 'metacore';
import {objectFromQuery} from 'utils';
import {useStore} from 'state';

import T2SController from 'components/T2SController';
import WaypointController from 'components/WaypointController';
import FlyController from 'components/FlyController';

export default function MainScene({mpModelId, characterId, characterPosition, characterDirection, onLoad}) {
  const sumerian = useStore((state) => state.sumerian);
  const setSumerian = useStore((state) => state.setSumerian);
  const avatarNavigation = useStore((state) => state.avatarNavigation);
  const setAvatarNavigation = useStore((state) => state.setAvatarNavigation);

  let currentSweepId = useRef(null);
  let currentSdk = useRef(null);

  // Forward url params.
  const params = objectFromQuery();
  params.m = params.m || mpModelId;
  params.play = params.play || '1';
  params.qs = params.qs || '1';
  params.sr = params.sr || '-.15';
  params.ss = params.ss || '25';

  // ensure applicationKey is inserted into the bundle query string
  params.applicationKey = params.applicationKey || MP_SDK_KEY;
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const src = `/bundle/showcase.html?${queryString}`;

  const createCameraControl = async (theSdk: any) => {
    const cameraNode = await theSdk.Scene.createNode();
    const cameraPose = await theSdk.Camera.getPose();

    const cameraInput = cameraNode.addComponent(cameraInputType);
    // convert sdk pose to THREE.js objects
    cameraInput.inputs.startPose = {
      position: new Vector3(cameraPose.position.x, cameraPose.position.y, cameraPose.position.z),
      quaternion: new Quaternion().setFromEuler(new Euler((cameraPose.rotation.x * Math.PI) / 180, (cameraPose.rotation.y * Math.PI) / 180, ((cameraPose.rotation.z || 0) * Math.PI) / 180, 'YXZ')),
      projection: new Matrix4().fromArray(cameraPose.projection).transpose(),
    };

    const cameraControl = cameraNode.addComponent('mp.camera');
    cameraControl.bind('camera', cameraInput, 'camera');

    cameraNode.start();

    //Moniter the camera pose
    theSdk.Camera.pose.subscribe((pose) => {
      // Changes to the Camera pose have occurred.
      // console.log('Current position is ', pose.position);
      // console.log('Rotation angle is ', pose.rotation);
      // console.log('Sweep UUID is ', pose.sweep);
      // console.log('View mode is ', pose.mode);
    });

    //Init camera rotation
    const initRotation = MP_MODEL_DATA.find((m) => m.code === mpModelId).camera.rotation || {x: 0, y: 0};
    setTimeout(() => {
      theSdk.Camera.setRotation(initRotation, {speed: 90})
        .then(() => {
          // Camera rotation complete.
        })
        .catch((error) => {
          // Camera rotation error.
        });
    }, 1000);
  };

  /**
   * Add a ambient light to the scene.
   * @param {any} theSdk
   */
  const addAmbientLight = async (theSdk: any) => {
    const [sceneObject] = await theSdk.Scene.createObjects(1);
    const node = sceneObject.addNode();
    const initial = {
      enabled: true,
      color: {
        r: 1,
        g: 1,
        b: 1,
      },
      intensity: 1.5,
    };

    node.addComponent('mp.ambientLight', initial);
    node.start();
  };

  /**
   * Add a directional light to the scene.
   * @param {any} theSdk
   */
  const addDirectionalLight = async (theSdk: any) => {
    const [sceneObject] = await theSdk.Scene.createObjects(1);
    const node = sceneObject.addNode();
    const initial = {
      enabled: true,
      color: {
        r: 1,
        g: 1,
        b: 1,
      },
      intensity: 1.5,
      position: {
        x: 1,
        y: 2,
        z: 1,
      },
      target: {
        x: 0,
        y: 0,
        z: 0,
      },
      debug: false,
    };

    node.addComponent('mp.directionalLight', initial);
    node.start();
  };

  /**
   * Load navigation model to the scene.
   * @param {any} theSdk
   */
  const loadNavigationMesh = async (theSdk: any) => {
    const node = await theSdk.Scene.createNode();
    const initial = {
      url: `/public/assets/glTF/navigations/${mpModelId}.glb`,
      // visible: true,
      // localScale: { x: 0.9, y: 0.9, z: 0.9 },
      localPosition: {x: 0, y: 0, z: 0},
      // localRotation: { x: 0, y: 0, z: 0 }
    };
    node.addComponent('mp.gltfLoader', initial);
    node.start();
  };

  const getIntersection = (theSdk: any) => {
    theSdk.Pointer.intersection.subscribe((intersectionData: any) => {
      // console.log('Intersection', intersectionData.position);
    });
  };

  const angle = (anchor, point) => (Math.atan2(anchor.z - point.z, anchor.x - point.x) * 180) / Math.PI;

  const rotateCameraTo = async (targetPosition, theSdk: any) => {
    try {
      let currentPose = await theSdk.Camera.getPose();
      let angleToTarget = angle(currentPose['position'], targetPosition);
      // correct for the cameras current pose rotation
      if (angleToTarget < 0) {
        angleToTarget = -(-angleToTarget - currentPose['rotation']['y'] + 90);
      } else {
        angleToTarget = angleToTarget + currentPose['rotation']['y'] - 90;
      }
      // correct for turns larger that 180 degrees
      if (Math.abs(angleToTarget) > 180) {
        angleToTarget = (360 - Math.abs(angleToTarget)) % 180;
      }
      await theSdk.Camera.rotate(angleToTarget);
    } catch (err) {
      console.log(err);
    }
  };

  async function goToNext(opt, theSdk: any) {
    await rotateCameraTo(opt.data.position, theSdk);

    await theSdk.Sweep.moveTo(opt.data.id, {
      transition: theSdk.Sweep.Transition.FLY,
    });
  }

  async function gotoSweepGraph(sweepId, theSdk: any) {
    const sweepGraph = await theSdk.Sweep.createGraph();
    const startSweep = sweepGraph.vertex(currentSweepId.current);
    const endSweep = sweepGraph.vertex(sweepId);

    // do standard path finding
    const aStarRunner = theSdk.Graph.createAStarRunner(sweepGraph, startSweep, endSweep);
    const path = aStarRunner.exec().path;
    console.log('Calculated Sweep Graph:', path);

    for (let i = 0; i < path.length; i++) {
      await goToNext(path[i], theSdk);
    }
  }

  const setupFlyView = (theSdk: any) => {
    theSdk.Sweep.current.subscribe(function (currentSweep) {
      if (currentSweep.sid) {
        currentSweepId.current = currentSweep.sid;
      }
    });

    theSdk.Sweep.data.subscribe({
      onCollectionUpdated: function (collection) {
        const reversedSweeps = Object.keys(collection).reverse();
        try {
          reversedSweeps.forEach((key) => {
            if (collection[key].alignmentType == 'aligned') {
              console.log('Navigating to Sweep ID: ' + key);
              gotoSweepGraph(key, theSdk);
              throw 'Break';
            }
          });
        } catch (e) {
          if (e !== 'Break') throw e;
        }
      },
    });
  };

  const createScene = async (MP_SDK_KEY: any) => {
    onLoad(true);
    try {
      const sdk = await GetSDK('sdk-iframe', MP_SDK_KEY);
      currentSdk.current = sdk;
      await initComponents(sdk);

      //Load navigation mesh
      // await loadNavigationMesh(sdk);

      //Create camera control
      await createCameraControl(sdk);

      //Add lights
      await addAmbientLight(sdk);
      await addDirectionalLight(sdk);

      //Detect cursor position
      getIntersection(sdk);

      /////////////////
      const scenes = await sdk.Scene.query(['scene']);
      const threeScene = scenes[0];
      const cameraRig = threeScene.getObjectByName('CameraRig');
      const threeCamera = cameraRig.camera;

      //Configure scene
      await sdk.Scene.configure((renderer: WebGLRenderer, three: any) => {
        renderer.physicallyCorrectLights = true;
        renderer.shadowMap.enabled = true;
        renderer.outputEncoding = sRGBEncoding;
      });

      //Init Sumerian
      const newSumerian = new Sumerian(sdk, threeScene, threeCamera);
      await newSumerian.setupPolly();

      const character = CHARACTER_DATA.find((c) => c.id === characterId);
      const mData = MP_MODEL_DATA.find((m) => m.code === mpModelId);
      if (mData) {
        character.position = mData.waypoints[0];
      }

      await newSumerian.loadCharacter(character);
      setSumerian(newSumerian);

      //Init AvatarNavigation
      const gltfLoader = new GLTFLoader();
      gltfLoader.loadAsync(`/public/assets/glTF/navigations/${mpModelId}.glb`).then((gltf) => {
        const newAvatarNavigation = new AvatarNavigation(
          // @ts-ignore
          gltf.scene.children[0],
        );
        newAvatarNavigation.setupPlayer(newSumerian.currentCharacter.node);
        setAvatarNavigation(newAvatarNavigation);

        //Finish loading
        onLoad(false);
      });
    } finally {
      onLoad(false);
    }
  };

  //Change matterport model
  useEffect(() => {
    createScene(MP_SDK_KEY);
  }, [mpModelId]);

  //Change character model
  useEffect(() => {
    if (!sumerian) {
      return;
    }

    onLoad(true);
    const switchCharacter = async () => {
      try {
        await sumerian.loadCharacter(CHARACTER_DATA.find((c) => c.id === characterId));
        avatarNavigation.setupPlayer(sumerian.currentCharacter.node);
        onLoad(false);
      } finally {
        onLoad(false);
      }
    };

    switchCharacter();
  }, [characterId]);

  //Change character position
  useEffect(() => {
    if (!sumerian) {
      return;
    }

    sumerian.setCharacterPosition(characterPosition);
  }, [characterPosition]);

  //Change character direction
  useEffect(() => {
    if (!sumerian) {
      return;
    }

    sumerian.setCharacterDirection(characterDirection);
  }, [characterDirection]);

  return (
    <Holder>
      <T2SController />
      <FlyController
        action={() => {
          setupFlyView(currentSdk.current);
        }}
      />
      <WaypointController mpModelId={mpModelId} />
      <Frame src={src} />
    </Holder>
  );
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
