export function banner(title: string): void {
  console.log();
  console.log('=======================================');
  console.log('\t' + title);
  console.log('=======================================');
}

export function heading(title: string): void {
  console.log();
  console.log('> ' + title);
}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: {[param: string]: string} = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};

export const getTimeNow = () => {
  let currentdate = new Date();
  let datetime = currentdate.getDate() + '_' + (currentdate.getMonth() + 1) + '_' + currentdate.getFullYear() + '_' + currentdate.getHours() + '_' + currentdate.getMinutes() + '_' + currentdate.getSeconds();

  return datetime;
};
