import React from 'react';
import styled from 'styled-components';
import {Button} from 'primereact/button';
import {motion} from 'framer-motion';

export default function TextButton(props) {
  const {delay} = props;
  return (
    <div style={{padding: '0.5em', overflow: 'hidden'}}>
      <motion.div
        transition={{
          delay: delay || 0,
          scale: {type: 'spring', damping: 10, mass: 0.75, stiffness: 100},
          default: {duration: 0.6},
        }}
        initial={{scale: 0.5, opacity: 0}}
        animate={{scale: 1, opacity: 1}}
      >
        <StyledButton {...props} className="p-button-raised">
          {props.children}
        </StyledButton>
      </motion.div>
    </div>
  );
}

const StyledButton = styled(Button)`
  border-radius: 10px;
  padding: 1em 2em;
`;
