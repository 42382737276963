import {Vector3, Mesh, Clock, Quaternion, Matrix4} from 'three';
import {Pathfinding} from 'three-pathfinding';
import {gsap, Power4} from 'gsap';
import {ISceneNode} from '@mp/common';

const ZONE_ID = 'level';
const SPEED = 0.8;

/**
 * 3D Character Navigation System for pathfinding
 */
export default class AvatarNavigation {
  path: any;
  playerPosition: Vector3;
  targetPosition: Vector3;
  pathfinder: Pathfinding;
  clock: Clock;
  player: ISceneNode;

  /**
   * @constructor
   * @param {Mesh} navMesh - Navigation mesh that exported glb from UnityEngine
   */
  constructor(navMesh: Mesh) {
    this.clock = new Clock();

    this.playerPosition = new Vector3();
    this.targetPosition = new Vector3();

    // Create level
    this.pathfinder = new Pathfinding();
    this.pathfinder.setZoneData(ZONE_ID, Pathfinding.createZone(navMesh.geometry));

    this.animate();
  }

  /**
   * @public
   * @param {ISceneNode} player - Player node
   */
  public setupPlayer(player: ISceneNode) {
    this.player = player;

    this.playerPosition.x = player.position.x;
    this.playerPosition.y = player.position.y;
    this.playerPosition.z = player.position.z;

    this.targetPosition.x = player.position.x;
    this.targetPosition.y = player.position.y;
    this.targetPosition.z = player.position.z;
  }

  /**
   * @public
   * @param {Vector3} point - Target position
   */
  public moveTo(point: Vector3) {
    this.targetPosition.copy(point);

    // Find path from A to B.
    const groupID = this.pathfinder.getGroup(ZONE_ID, this.playerPosition);
    this.path = this.pathfinder.findPath(this.playerPosition, this.targetPosition, ZONE_ID, groupID);
  }

  /**
   * Update function for animation
   * @private
   */
  animate(): void {
    requestAnimationFrame(this.animate.bind(this));

    if (!(this.path || []).length) {
      return;
    }

    const velocity = this.path[0].clone().sub(this.playerPosition);

    if (velocity.lengthSq() > 0.1 * 0.1) {
      velocity.normalize();

      const delta = velocity.multiplyScalar(SPEED * this.clock.getDelta());
      if (delta.length() > 0.02) {
        return;
      }

      this.playerPosition.add(delta);

      // Move player to target
      gsap.to(this.player.position, {
        x: this.playerPosition.x,
        y: this.playerPosition.y,
        z: this.playerPosition.z,
        duration: 0.4,
        delay: 0,
        ease: Power4.easeOut,
      });

      const mx = new Matrix4().lookAt(velocity, new Vector3(0, 0, 0), new Vector3(0, 1, 0));
      const q = new Quaternion().setFromRotationMatrix(mx);

      // Rotate player to direction of target
      gsap.to(this.player.quaternion, {
        y: q.y,
        w: q.w,
        duration: 0.8,
        delay: 0,
        ease: Power4.easeOut,
      });
    } else {
      // Remove node from the path calculated
      this.path.shift();
    }
  }
}
